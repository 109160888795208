@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Product-Sans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../assets/fonts/Product-Sans-Bold.ttf') format('ttf');
}
