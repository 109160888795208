@import './fonts';

#root {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: Muli, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a, p, button, input, span {
    font-family: "Product Sans", Muli, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
}

.home-container {
  width: 100%;
}

.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  padding: 0.5rem 0;
  z-index: 120;

  .container {
    display: flex !important;
    padding: 0;
    justify-content: space-between;
  }

  .nav-links {
    transition: color 0.25s ease;
    cursor: pointer;
    margin: 0 1rem;
    font-size: 18px;
    display: block;
    text-align: center;
  }

  img {
    cursor: pointer;
    height: 50px;
    object-fit: contain;
  }
}

#top-section {
  min-height: 100vh;
  background-image: url('../assets/header-bg.png');
  background-position: top right;
  background-repeat: no-repeat;

  .top-logo {
    max-width: 228px;
    object-fit: contain;
    margin-bottom: 1rem;
  }

  h1 {
    font-weight: bold;
    color: #222D7F;
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }

  p {
    color: #6E6E6E;
    font-size: 18px;
  }

  .intro-section {
    height: 100%;
    padding: 15% 0;
  }

  .doctor-section {
    padding: 80px 0;
    background-color: #F6F7FB;
  }

  .container {
    display: flex;
  }

  .img-siloam-home {
    position: absolute;
    top: -4rem;
    z-index: 10;
  }

  .img-siloam-diabisa {
    position: absolute;
    top: 0;
    left: 38.5%;
    z-index: 9;
  }
}

.mysiloam-primary-button {
  height: 60px;
  width: 186px;
  border-radius: 8px;
  background-color: #222D7F;
  color: #FFD40F;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #222D7F;
}

.phone-number-entry {
  .phone-input-group {
    display: flex;
    align-items: center;
    border: 1px solid #6E6E6E;
    border-radius: 8px;
    background-color: white;
    height: 60px;
    margin-bottom: 2rem;
    padding: 0.5rem 0.75rem;
    color: #6E6E6E;

    input {
      margin-left: 1rem;
      border: none;
      padding: 0;
    }
  }

  img {
    width: 100%;
  }
}

#hospital-info {
  padding: 5% 0;
  color: #6E6E6E;
  font-size: 18px;

  h1 {
    font-weight: bold;
    color: #222D7F;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .container {
    display: flex;
  }

  .section-hospital-description {
    padding: 5% 0;
  }

  .img-siloam-home {
    position: absolute;
    top: 35%;
    bottom: 0;
    left: 15.5%;
    margin: auto;
  }

  .img-siloam-diabisa {
    position: absolute;
    top: 0;
    right: 0;
  }
}

#book-your-doctor {
  padding: 5% 0;
  color: #6E6E6E;
  font-size: 18px;

  h1 {
    font-weight: bold;
    color: #222D7F;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  h4 {
    color: #222D7F;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .container {
    display: flex;
  }

  .section-doctor-description {
    padding: 5% 0;
    text-align: right;
  }

  .img-siloam-home {
    position: absolute;
    top: 20%;
    right: 15.5%;
    margin: auto;
  }

  .img-siloam-diabisa {
    position: absolute;
    top: -15%;
    left: 0;
    margin: auto;
  }
}

#diabisa {
  padding-top: 5%;
  padding-bottom: 2.5%;
  color: #6E6E6E;
  font-size: 18px;

  h1 {
    font-weight: bold;
    color: #222D7F;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .container {
    display: flex;
  }

  .section-diabisa-description {
    padding: 5% 0;
  }

  .img-siloam-home {
    position: absolute;
    top: 25%;
    bottom: 0;
    left: 15.5%;
    margin: auto;
  }

  .img-siloam-diabisa {
    position: absolute;
    top: -20%;
    right: 0;
    margin: auto;
  }
}

#hospital-list {
  color: #6E6E6E;
  font-size: 18px;
  position: relative;

  h1 {
    font-weight: bold;
    color: #222D7F;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .hospital-intro {
    background-image: url('../assets/siloam-hospital-bg.png');
    height: 65vh;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 80px;
    background-size: 30% 160%;
    position: relative;

    .container {
      height: 0;
      position: absolute;
      top: 0;
      left: 25%;
      right: 0;
      bottom: 0;
      margin: auto;
      max-width: 50%;
    }
  }

  .save-your-time {
    background-image: url('../assets/siloam-link-bg.jpg');
    padding: 5% 10%;
    padding-left: 25%;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .phone-number-entry {
        width: 80%;
      }
    }
  }

  .siloam-home-center-bg {
    position: absolute;
    top: 25%;
    left: 5%;
    bottom: 0;
    margin: auto;
  }
}

.customer-care-info {
  padding: 2% 0;
  text-align: center;
  font-size: 18px;
  color: #646782;

  a {
    text-decoration: none;
    color: #646782;
  }

  .row {
    margin-top: 1rem;

    a {
      margin: 0 1rem;
    }
  }
}

.footer-info {
  background-image: url('../assets/bottom-bg.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  padding: 1.5% 0;
  text-align: center;
  font-size: 18px;

  a {
    text-decoration: underline;
    text-underline-position: under;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  a, p {
    color: #646782;
  }
}

.privacy-policy-wrapper {
  min-height: calc(100vh - 80px);
  padding: 0 1rem;
  padding-top: 80px;
}

.mysiloam-sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: transform 0.25s;
  transform: translateX(75vw);
  width: 75vw;
  background-color: white;
  z-index: 99999;
  will-change: transform;
  padding: 1rem;

  .sidebar-header {
    button {
      margin-left: auto;
      display: block;
      padding: 0;

      span {
        font-size: 2.5rem;
        font-weight: bold;
        color: black;
      }
    }
  }

  .sidebar-content {
    color: #222D7F;
    font-weight: bold;

    ul {
      list-style-type: none;
      padding-left: 1rem;
      padding-top: 1rem;

      li {
        font-size: 1.25rem;
        margin: 1rem 0;
      }
    }
  }

  &.open {
    transform: translateX(0);
  }
}

.mysiloam-sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  margin: auto;
  z-index: -1;
  visibility: hidden;

  &.open {
    z-index: 99998;
    visibility: visible;
  }
}

@media (max-width: 576px) {
  .react-reveal {
    animation-name: none !important;
  }

  .header-wrapper {
    padding: 0.5rem 2rem;

    .navbar-toggler {
      border: none;
      outline: none;
    }
  }

  #top-section {
    background-position: unset;

    .top-created-text {
      font-size: 14px;
    }

    h1 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }

    .doctor-section {
      padding: 10vh 0;

      p {
        margin-top: 1.5rem;
        font-size: 14px;
        text-align: center !important;
        margin-bottom: 0;
      }

      img {
        max-width: 100%;
      }
    }

    .mobile-intro-images {
      padding: 8vh 0;

      img {
        width: 243px;
        height: 402px;
      }

      .img-siloam-home {
        position: relative;
        top: auto;
        margin-left: -2.5%;
      }
  
      .img-siloam-diabisa {
        top: 15vh;
        left: 37.5%;
      }
    }
  }

  #hospital-info {
    padding: 5% 1rem;
    font-size: 14px;

    h1 {
      font-size: 28px;
    }
    
    .section-hospital-description {
      padding: 5% 0.5rem 0;
    }

    img {
      width: 3rem;
      height: 3rem;
    }

    .img-siloam-home {
      height: 262px;
      width: 121px;
      top: 0;
      bottom: 0;
      left: 7.5%;
    }

    .img-siloam-diabisa {
      position: relative;
      width: 250px;
      height: 410px;
      display: block;
      margin-left: auto;
      margin-right: -2.5rem;
    }
  }

  #book-your-doctor {
    padding: 0 1rem;
    font-size: 14px;

    h1 {
      font-size: 28px;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0;
    }

    .section-doctor-description {
      text-align: left;
    }

    .img-siloam-diabisa {
      position: relative;
      width: 250px;
      height: 410px;
      display: block;
      margin-right: auto;
      margin-left: -2rem;
    }

    .img-siloam-home {
      height: 262px;
      width: 121px;
      right: 5%;
    }
  }

  #diabisa {
    padding: 10% 1rem 0;
    font-size: 14px;

    h1 {
      font-size: 28px;
    }

    .section-diabisa-description {
      padding: 0;
    }

    img {
      width: 3rem;
      height: 3rem;
    }

    .img-siloam-home {
      height: 262px;
      width: 121px;
      top: 0;
      bottom: 0;
      left: 7.5%;
    }

    .img-siloam-diabisa {
      position: relative;
      width: 250px;
      height: 410px;
      display: block;
      margin-left: auto;
      margin-right: -2.5rem;
    }
  }

  .mysiloam-primary-button {
    height: 48px;
    max-width: 150px;
    width: 100%;
    font-size: 14px;
  }

  .container {
    display: block !important;
  }

  .phone-number-entry {
    .phone-input-group {
      height: 48px;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  #hospital-list {
    padding-top: 10%;
    font-size: 14px;

    h1 {
      font-size: 28px;
    }

    .hospital-intro {
      height: auto;
      padding-bottom: 30vh;
      background-position-y: 140px;
      background-size: 60% 150%;

      h1 {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 0;
      }

      .container {
        position: relative;
        height: auto;
        left: auto;
        max-width: 100%;
      }
    }

    .save-your-time {
      padding: 5% 0;

      h1 {
        width: 45%;
        margin: 10vh -0.5rem;
        margin-left: auto;
      }

      .container .phone-number-entry {
        width: 100%;
        padding-top: 1rem;
      }
    }

    .siloam-home-center-bg {
      width: 250px;
      height: 410px;
      top: 10%;
      left: -1rem;
    }
  }

  .customer-care-info {
    padding: 5vh 0;
    font-size: 14px;
  }

  .footer-info {
    background-size: cover;
    font-size: 14px;
    padding: 5vh 0;

    p {
      margin-top: 0.75rem;
    }
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 80%;
  }

  .mysiloam-sidebar {
    display: none;
  }
}